<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" label-position="right" size="mini">
          <el-form-item label="名称" prop="name">
            <el-input :value="name" disabled></el-input>
          </el-form-item>
          <el-form-item label="满意度题目">
            <el-card shadow="never">
              <el-button size="mini" @click="addQuestionType">添加一级分类</el-button>
              <el-divider></el-divider>
              <!-- 一级 -->
              <el-tabs :stretch="true" v-model="questionTypeName" closable @tab-remove="removeQuestionType">
                <el-tab-pane v-for="(cateItem,catIndex) in form.categoryList" :key="cateItem.name" :label="cateItem.name" :name="cateItem.name">
                  <span slot="label">
                    <el-input v-model="cateItem.name" style="width: 80%" placeholder="请输入内容"></el-input>
                  </span>
                  <el-button size="mini" @click="addQuestionType2(catIndex)">添加二级分类</el-button>
                  <el-divider></el-divider>
                  <!-- 二级 -->
                  <el-tabs :tab-position="tabPosition" v-model="questionTypeName2">
                      <el-tab-pane v-for="(subItem,subIndex) in cateItem.subCategoryList" :key="subItem.name" :label="subItem.name" :name="subItem.name">
                        <span slot="label">
                          <el-input v-model="subItem.name" style="width: 80%" placeholder="若为空表示无二级分类"></el-input>
                          <i class="el-icon-close" style="margin-left: 10px;cursor:pointer" @click="removeQuestionType2(catIndex,subItem.name)"></i>
                        </span>
                        <el-card class="box-card-area" style="margin-bottom: 10px" :hehe="subItem.length" v-for="(item, index) in subItem.questionList" :key="index" shadow="never">
                          <el-row>
                            <el-col :span="20">
                              <el-form-item label="标识">
                                <!-- <el-input v-model="item.orderNum" placeholder="请输入标识符(标识符请勿重复并按顺序从小到大填写)"></el-input> -->
                                <!-- <span>{{numPush((catIndex+1)+'.'+(subItem.questionList.length),item)}}</span> -->
                                <span>{{numPush((catIndex),(index),form.categoryList,subIndex,item)}}</span>
                              </el-form-item>
                              <el-form-item label="题目名称">
                                <el-input v-model="item.title"></el-input>
                              </el-form-item>
                              <el-form-item label="题目类型">
                                  <el-radio-group @change="typeChange(item)" v-model="item.types">
                                      <el-radio label="单选"></el-radio>
                                      <el-radio label="多选"></el-radio>
                                      <el-radio label="填空"></el-radio>
                                  </el-radio-group>
                              </el-form-item>
                                <template v-if="item.types!='填空'">
                                  <el-row>
                                    <el-col :span="8">
                                        <el-form-item :label="'选项'+(_index+1)" :key="_index" v-for="(_item,_index) in item.optionList">
                                            <el-input v-model="_item.value"  placeholder="请填写选项(例：A)"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item :label="'选项值'+(_index+1)" :key="_index" v-for="(_item,_index) in item.optionList">
                                            <el-input v-model="_item.text" style="width: 84%" placeholder="请填写选项值(例：满意)"></el-input>
                                            <i v-if="_index!=0" class="el-icon-delete" style="margin-left: 10px;cursor:pointer" @click="delItem(catIndex,subIndex,index,_index)"></i>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-button @click="addItem(catIndex,subIndex,index)">新增选项</el-button>
                                    </el-col>
                                  </el-row>
                                  <el-form-item label="问题触发规则">
                                      <el-checkbox-group v-model="item.attachOptionRuleArray">
                                        <el-checkbox v-for="r in item.optionList" :key="r.value" :label="r.value">
                                        </el-checkbox>
                                      </el-checkbox-group>
                                  </el-form-item>
                                  <el-form-item label="附加问题">
                                      <el-input v-model="item.attachTitle" placeholder="请填写附加问题(例：不满意原因)"></el-input>
                                  </el-form-item>
                                  <el-form-item label="题目触发规则">
                                      <el-input v-model="item.conditionOption" placeholder="请填写触发关联题目的规则"></el-input>
                                  </el-form-item>
                                  <el-form-item label="关联题目">
                                    <el-input v-model="item.relationOrderStr" placeholder="请填写关联题目的标识"></el-input>
                                    <el-alert title="当关联题目的题目触发规则内的选项被选择时，该题目会显示出来" type="success"></el-alert>
                                  </el-form-item>
                              </template>
                            </el-col>
                            <el-col :span="4">
                              <el-button style="display: block; margin: 0 auto" type="danger" @click="onRemove(catIndex,subIndex,index)">移除</el-button>
                            </el-col>
                          </el-row>
                        </el-card>
                        <el-row>
                          <el-col :span="12">
                            <el-button style="display: block; margin: 0 auto" type="primary" @click="addQuestion(catIndex,subIndex)">添加检查项</el-button>
                          </el-col>
                          <el-col :span="12">
                            <el-button style="display: block; margin: 0 auto" type="primary" @click="addQuestionWithClass(catIndex,subIndex)">通过题库添加检查项</el-button>
                          </el-col>
                        </el-row>
                      </el-tab-pane>
                  </el-tabs>
                </el-tab-pane>
              </el-tabs>
            </el-card>
          </el-form-item>
          <el-form-item style="overflow: hidden">
            <el-button type="primary" @click="onSubmit" style="float: right">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 一级 -->
    <el-dialog title="一级分类名称" width="20%" :visible.sync="questionVisible">
      <el-form :model="form">
        <el-form-item>
          <el-input
            v-model="questionTypeName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="questionVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="doAddQuestionType">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 二级 -->
    <el-dialog title="二级分类名称" width="20%" :visible.sync="questionVisible2">
      <el-form :model="form">
        <el-form-item>
          <el-input v-model="questionTypeName2" autocomplete="off" placeholder="若没有二级分类不需要填写"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="questionVisible2 = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="doAddQuestionType2">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除一级 -->
    <el-dialog title="提示" width="20%" :visible.sync="removeVisible">
      <p>是否确认删除{{ questionTypeName }}！</p>
      <p>注意，该类型下的所有题目都将被删除</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="removeVisible = false">取 消</el-button>
        <el-button type="primary" @click="doRemoveQuestionType">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除二级 -->
    <el-dialog title="提示" width="20%" :visible.sync="removeVisible2">
      <p>是否确认删除{{ questionTypeName2 }}！</p>
      <p>注意，该类型下的所有题目都将被删除</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="removeVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="doRemoveQuestionType2">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="添加题目" width="80%" :visible.sync="classQuestionVisible">
      <el-row>
        <el-col :span="4">
          <el-tree :data="classList" :props="{children: 'children', label: 'label'}" @node-click="nodeClick">
            <span class="custom-tree-node" slot-scope="{node}" style="width: 100%">
                <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-col>
        <el-col :span="19" :offset="1">
          <el-row style="margin-top: 5px">
            <el-col :span="24">
                <div class="cardTitle">{{secondClassName}}</div>
            </el-col>
            <el-col :span="24">
                <el-table :data="questionList" @selection-change="handleSelectionChange">
                  <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="题目" prop="title"></el-table-column>
                    <el-table-column label="题目类型" prop="types"></el-table-column>
                </el-table>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="classQuestionVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddQuestionWithClass">确 定</el-button>
      </div>
    </el-dialog>
    <el-backtop></el-backtop>
  </el-container>
</template>
<style>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card-question {
  width: 100%;
}
</style>
<script>
export default {
  data() {
    return {
      image_: require("../../assets/icons-plus.png"),
      tabPosition: "left",
      form: {
        surveyGuid: "",
        categoryList: [],
      },
      questionTypeName: "",
      questionTypeName2: "",
      activeName: "1",
      userInfo: {},
      allDeptPosition: [],
      rules: {},
      questionVisible: false,
      questionVisible2: false,
      removeVisible: false,
      removeVisible2: false,
      classQuestionVisible: false,
      classList: [],
      secondClassName: '',
      classGuid: '',
      questionList: [],
      classQuestion: [],
      classIndex2: '',
      delIndex2: '',
      addCatIndex: '',
      addSubIndex: '',
      name: ''
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.name = self.$route.query.name
    self.getSurveyDetail(self.$route.query.guid || null);
  },
  methods: {
    getSurveyDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/question/list2/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function(response) {
            console.log(response);
            self.form.categoryList = response.data.data;
            // console.log(JSON.stringify(self.form.categoryList));
          })
          .catch(function(error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      } else {
        self.form = {
          name: "",
        };
      }
    },
    resetOrderNum() {
      var i = 0;
      let self = this;
      self.form.questionList.forEach((element) => {
        element.orderNum = ++i;
      });
    },
    onSubmit() {
      let self = this;
      self.form.surveyGuid = self.$route.query.guid
      let data = JSON.parse(JSON.stringify(self.form));
      console.log('form=',self.form);
      console.log('data=',data);
      self.form.categoryList.forEach(item => {
        console.log('item=',item);
        item.subCategoryList.forEach(_item => {
          console.log('_item=',_item);
          // _item.questionList.forEach(__item => {
          //   console.log(__item);
          //   console.log(__item.orderNum);
          // })
          Object.keys(_item.questionList).sort(function(a, b) {
              return _item.questionList[b].orderNum - _item.questionList[a].orderNum;
          })
        })
      })
      
      self.$axios
        .post("/survey/compose", JSON.stringify(data), {
          headers: {
            "content-type": "application/json",
            token: self.userInfo.token,
          },
        })
        .then(function() {
          self.$message({
            message: "操作成功",
            type: "success",
          });
          self.$router.push({ name: "ProjectSatisfaction"});
        })
        .catch(function(error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.account || "未命名用户") + "？")
        .then(() => {
          self.$axios
            .delete("/staff/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "staff_list" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    // 移出题目
    onRemove(catIndex,subIndex,index) {
      console.log(index);
      let self = this;
      self.form.categoryList[catIndex].subCategoryList[subIndex].questionList.splice(index, 1);
    },
    // 添加一级分类
    addQuestionType() {
      let self = this;
      self.questionTypeName = "";
      self.questionVisible = true;
    },
    // 确认添加一级分类
    doAddQuestionType() {
      let self = this;
      let newQuestionType = {
        name: self.questionTypeName,
        subCategoryList: [],
      };
      self.form.categoryList.push(newQuestionType);
      self.questionVisible = false;
      console.log(self.form);
    },
    // 添加二级分类
    addQuestionType2(index) {
      let self = this;
      self.classIndex2 = index
      self.questionTypeName2 = "";
      self.questionVisible2 = true;
    },
    // 确认添加二级分类
    doAddQuestionType2() {
      let self = this;
      let newQuestionType = {
        name: self.questionTypeName2,
        questionList: [],
      };
      // let _index = self.findQuestionTypeIndex();
      self.form.categoryList[self.classIndex2].subCategoryList.push(newQuestionType);
      self.questionVisible2 = false;
      console.log('doaddquestion');
      console.log(self.form);
    },
    // 删除一级
    removeQuestionType(questionType) {
      let self = this;
      console.log("removeQuestionType");
      console.log(questionType);
      self.questionTypeName = questionType;
      self.removeVisible = true;
    },
    // 确认删除一级
    doRemoveQuestionType() {
      let self = this;
      console.log(self.form.categoryList);
      self.form.categoryList = self.$underscore.filter(self.form.categoryList, function(
        _el
      ) {
        return _el.name != self.questionTypeName;
      });
      console.log(self.form.categoryList);
      if (self.form.categoryList.length == 0) {
        self.questionTypeName = "";
      } else {
        self.questionTypeName = self.form.categoryList.name;
      }
      self.removeVisible = false;
    },
    // 删除二级
    removeQuestionType2(catIndex,questionType) {
      let self = this;
      console.log("removeQuestionType");
      console.log('val=',questionType);
      console.log('i=',catIndex);
      self.questionTypeName2 = questionType;
      self.delIndex2 = catIndex
      self.removeVisible2 = true;
    },
    // 确认删除二级
    doRemoveQuestionType2() {
      let self = this;
      console.log('c=',self.form.categoryList);
      console.log('del=',self.delIndex2);
      console.log('val=',self.questionTypeName2);
      self.form.categoryList[self.delIndex2].subCategoryList.forEach((item,index) => {
        console.log(item,index);
        if(item.name == self.questionTypeName2) {
          console.log('in=',index);
          self.form.categoryList[self.delIndex2].subCategoryList.splice(index,1)
        }
      })
      self.removeVisible2 = false;
    },
    // 添加题目
    addQuestion(catIndex,index) {
      let self = this;
      let newQuestion = {
        title: "",
        types: "",
        optionList: [{
          text: '',
          value: ''
        }],
        attachTitle: "",
        attachOptionRuleArray: [],
        orderNum: null,
        conditionOption:"",
        relationOrderStr:""
      };
      console.log('add');
      self.form.categoryList[catIndex].subCategoryList[index].questionList.push(newQuestion);
      console.log(self.form);
    },
    findQuestionTypeIndex() {
      let self = this;
      let _index = 0;
      self.$underscore.each(self.form.categoryList, function(_el, index) {
        console.log("index = ", index);
        if (_el.name == self.questionTypeName) {
          _index = index;
        }
      });
      return _index;
    },
    // 点击通过题库添加检查项
    addQuestionWithClass(catIndex,subIndex) {
      let self = this
      self.getClassTreeStructure()
      self.questionList = []
      self.secondClassName = ''
      self.addCatIndex = catIndex
      self.addSubIndex = subIndex
      self.classQuestionVisible = true
    },
    // 获取分类树状结构
    getClassTreeStructure() {
      let self = this;
      self.$axios.get('/survey-category/tree',{
          headers: { token: self.userInfo.token }
      })
      .then(function(res) {
          self.classList = res.data.data
      })
      .catch(function(err) {
          self.$message({
              message: "分类列表获取失败：" + err.response.data.message,
              type: "warning"
          });
      })
    },
    // 展示题目
    nodeClick(data,node) {
      let self = this
      if(node.level == 2) {
          self.secondClassName = data.label
          self.classGuid = data.value
          self.getQuestion(self.classGuid)
      }
    },
    // 获取题目
    getQuestion(guid) {
      let self = this
      self.$axios.get('/survey-question/list?categoryGuid='+guid,{
          headers: { token: self.userInfo.token }
      })
      .then(function(res) {
          self.questionList = res.data.data
          console.log(guid);
          console.log(self.questionList);
      })
      .catch(function (error) {
          self.$message({
          message: "题目列表获取失败：" + error.response.data.message,
          type: "warning",
          });
      });
    },
    // 选中题目数据
    handleSelectionChange(val) {
      let self = this
      self.classQuestion = val
      console.log(self.classQuestion);
    },
    // 确认通过题库添加检查项
    doAddQuestionWithClass() {
      let self = this
      let newQuestion = self.classQuestion
      console.log(newQuestion);
      console.log(self.addCatIndex);
      console.log(self.addSubIndex);
      newQuestion.forEach(item => {
        self.form.categoryList[self.addCatIndex].subCategoryList[self.addSubIndex].questionList.push(item);
      })
      console.log(self.form);
      self.classQuestionVisible = false
    },
    // 添加新选项
    addItem(catIndex,subIndex,index) {
      let self = this
      self.form.categoryList[catIndex].subCategoryList[subIndex].questionList[index].optionList.push({
          text: '',
          value: ''
      });
    },
    // 删除新选项
    delItem(catIndex,subIndex,index,__index) {
      let self = this
      self.form.categoryList[catIndex].subCategoryList[subIndex].questionList[index].optionList.splice(__index,1)
      console.log(index);
    },
    // 类型变化时
    typeChange(item) {
      console.log(item);
      if(item.types == '填空') {
        item.optionList = []
        item.attachOptionRuleArray = [],
        item.attachTitle = ""  
      }
      console.log('rule=',item.attachOptionRuleArray);
    },
    numPush(index,arr,list,sIndex,item) {
      // index 以及分类的索引
      // arr 每一题的索引
      // list form.categoryList
      // sIndex 二级分类的索引
      // item 每一题的所有属性
      // 是一级分类的第一个
      if(index == 0) {
        item.orderNum = arr+1
        return item.orderNum
        // 不是一级分类的第一个
      } else {
        // 是二级分类的第一个
        if(sIndex==0) {
          let subLength = list[index-1].subCategoryList.length
          let queLength = list[index-1].subCategoryList[sIndex].questionList.length
          // 前一个一级分类的最后一个二级分类的questionList长度不为0
          if(list[index-1].subCategoryList[subLength-1].questionList.length!=0) {
            item.orderNum = ((list[index-1].subCategoryList[subLength-1].questionList[list[index-1].subCategoryList[subLength-1].questionList.length-1].orderNum) + 1 + arr)
            // 前一个一级分类的最后一个二级分类的questionList长度为0
          } else {
            item.orderNum = ((list[index-1].subCategoryList[subLength-2].questionList[queLength-1].orderNum) + 1 + arr)
          }
          return item.orderNum
          // 不是二级分类的第一个
        } else {
          item.orderNum = ((list[index].subCategoryList[sIndex-1].questionList[list[index].subCategoryList[sIndex-1].questionList.length-1].orderNum) + 1 + arr)
          return item.orderNum
        }
      }
    }
  }
};
</script>
